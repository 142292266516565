var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{ref:"observer"},[_c('h3',[_vm._v("更新方法")]),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-select',{staticClass:"mt-2",attrs:{"name":"update_type","items":_vm.updateTypeItem,"no-data-text":"更新方法を選択","label":"更新方法","success":"","outlined":"","hint":"更新方法を選択して下さい[必須項目]","persistent-hint":"","rules":_vm.require_rule},model:{value:(_vm.update_type),callback:function ($$v) {_vm.update_type=$$v},expression:"update_type"}})],1)],1)],1),_c('h3',[_vm._v("カテゴリ")]),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-select',{staticClass:"mt-2",attrs:{"name":"news_category","items":_vm.newsCategories,"no-data-text":"カテゴリを選択","label":"カテゴリ","success":"","outlined":"","hint":"カテゴリを選択して下さい[必須項目]","persistent-hint":"","rules":_vm.require_rule},model:{value:(_vm.news_category),callback:function ($$v) {_vm.news_category=$$v},expression:"news_category"}})],1)],1)],1),_c('h3',[_vm._v("期間（開始〜終了）")]),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('ValidationProvider',{attrs:{"name":"掲載開始日","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{staticClass:"ml-1 mt-2",attrs:{"label":"掲載開始日","name":"start_date","outlined":"","type":"date","hint":"空白時は当日を自動設定します[任意項目]","persistent-hint":"","error-messages":errors,"success":valid,"clearable":true},model:{value:(_vm.start_date),callback:function ($$v) {_vm.start_date=$$v},expression:"start_date"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('ValidationProvider',{attrs:{"name":"掲載終了日","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{staticClass:"ml-1 mt-2",attrs:{"label":"掲載終了日","name":"end_date","outlined":"","type":"date","hint":"[任意項目]","persistent-hint":"","error-messages":errors,"success":valid,"clearable":true},model:{value:(_vm.end_date),callback:function ($$v) {_vm.end_date=$$v},expression:"end_date"}})]}}])})],1)],1)],1),_c('h3',[_vm._v("タイトル")]),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"タイトル","rules":"required|max:40"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{staticClass:"mt-2",attrs:{"name":"title","counter":"40","error-messages":errors,"success":valid,"label":"タイトル","outlined":"","hint":"タイトルは40文字以内で設定してください[必須項目]","persistent-hint":"","background-color":"white"},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}})]}}])})],1)],1)],1),_c('h3',{attrs:{"id":"body_head"}},[_vm._v("内容")]),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"内容","rules":"required|max:500"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-textarea',{staticClass:"mt-2",attrs:{"name":"body","counter":"500","error-messages":errors,"success":valid,"label":"内容","rows":"12","outlined":"","hint":"内容は500文字以内で設定してください[必須項目]","persistent-hint":"","background-color":"white"},model:{value:(_vm.body),callback:function ($$v) {_vm.body=$$v},expression:"body"}})]}}])})],1)],1)],1),_c('imageComponent',{ref:"refImageComponent",attrs:{"template_config":_vm.template_config,"is_contain":true}}),_c('p',[_vm._v(" ※投稿できる画像は1枚まで。横420px×縦315px（4：3の横長）を推奨サイズとしています。 ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }