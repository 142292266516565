<template>
  <div>
    <v-app-bar flat color="white">
      <div class="pa-2">
        <template-dialog-component
          ref="refDialogComponent"
          @save="save"
        ></template-dialog-component>
      </div>
      <v-spacer></v-spacer>
    </v-app-bar>
  </div>
</template>

<script>
import { ref } from "@vue/composition-api";
import templateDialogComponent from "./component/templateDialogComponent.vue";

export default {
  components: {
    templateDialogComponent,
  },
  props: ["content_format_list"],
  setup(props, ctx) {
    const refDialogComponent = ref(null);

    // app-barにあるコンポーネント呼び出し
    const action = (params) => {
      switch (params.action) {
        case "edit":
          refDialogComponent.value.editDialog(
            params.value
            // params.contentFormats
          );
          break;
      }
    };

    // サイトが新規追加されたとき
    const save = () => {
      ctx.emit("reload", 0);
    };

    return {
      refDialogComponent,
      action,
      contentFormatList: props.content_format_list,
      save,
    };
  },
};
</script>
