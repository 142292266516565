<template>
  <div>
    <div>
      <h3 id="image_head">画像</h3>
      <div class="mt-2 mb-2">
        画像を設定してください。{{ setting.template_image_number }}枚まで指定可
      </div>
      <div class="image_flame">
        <draggable
          v-model="uploadImage"
          v-bind="dragOptions"
          handle=".list_num"
          :force-fallback="true"
          class="image_box"
        >
          <div
            v-for="(image, index) in uploadImage"
            :key="index"
            class="image_card"
          >
            <v-btn icon class="list_num image_drag_btn" color="white">
              <v-icon>mdi-drag</v-icon>
            </v-btn>
            <v-card loading="false" outlined tile width="167">
              <v-img
                height="220px"
                :contain="is_contain"
                :src="
                  uploadImage[index].image_path
                    ? setting.cdn + uploadImage[index].image_path
                    : uploadImage[index].image_base64
                "
                v-if="
                  uploadImage[index].image_path ||
                  uploadImage[index].image_base64
                "
              >
              </v-img>
              <v-img
                height="220px"
                :contain="isContain"
                src="@/assets/images/no_image.jpg"
                v-else
              ></v-img>
              <v-card-actions>
                <input
                  name="upload_file"
                  ref="upload"
                  type="file"
                  style="display: none"
                  :upload_index="index"
                  @change="onImageUploaded"
                />
                <v-btn icon>
                  <v-icon @click="$refs.upload[index].click()"
                    >mdi-upload</v-icon
                  >
                </v-btn>
                <v-btn icon>
                  <v-icon @click="onCastSelect(index)">mdi-face-woman</v-icon>
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn icon>
                  <v-icon @click="onImageDelete(index)">mdi-delete</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </div>
        </draggable>
      </div>
      <h3 id="image_head">画像の更新方法</h3>
      <div class="mt-2 mb-2">
        画像が指定できるコンテンツに限り、更新方法が選択できます。
      </div>
      <v-radio-group v-model="item.imagechangemode" row mandatory>
        <v-radio label="指定なし" value="0"></v-radio>
        <v-radio label="順次切替" value="1"></v-radio>
        <v-radio label="ランダム" value="2"></v-radio>
      </v-radio-group>
    </div>
    <!-- キャスト選択ダイアログ -->
    <v-dialog
      v-model="castDialog"
      scrollable
      persistent
      width="100%"
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">キャスト選択</span>
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="キャスト名検索"
            single-line
            hide-details
          ></v-text-field>
          <v-data-table
            :headers="headers"
            :items="casts"
            :search="search"
            mobile-breakpoint=""
            @click:row="onClickList"
            :footer-props="{
              'items-per-page-options': [50, 100, -1],
              'items-per-page-text': '表示件数',
            }"
          >
            <!-- eslint-disable-next-line -->
                    <template v-slot:footer.page-text="props">
              {{ props.pageStart }} ～{{ props.pageStop }}件／全{{
                props.itemsLength
              }}件
            </template>
            <!-- eslint-disable-next-line -->
                    <template v-slot:item.image="{ item }">
              <v-avatar class="ma-1">
                <v-img :src="setting.cdn + item.image" />
              </v-avatar>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-container>
            <v-row justify="end">
              <v-col cols="6" sm="3">
                <general-button
                  btn_color="gray"
                  btn_block
                  @click-event="cancelCastDialog"
                  ><template v-slot:icon
                    ><v-icon left>mdi-close-circle</v-icon></template
                  >キャンセル</general-button
                >
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  set,
  ref,
  reactive,
  toRefs,
  defineComponent,
  onMounted,
} from "@vue/composition-api";
import draggable from "vuedraggable";
import { repositoryFactory } from "@/repository/repositoryFactory";
import store from "@/store/index.js";
import setting from "@/common/setting.js";

export default defineComponent({
  components: {
    draggable,
  },
  props: ["template_config", "is_contain"],
  setup(props, ctx) {
    const castsRepository = repositoryFactory.get("casts");
    const fileRepository = repositoryFactory.get("file");

    const state = reactive({
      castDialog: false, // ダイアログ表示用
      castDialogIndex: 1,
      isContain: props.is_contain ? props.is_contain : false,
      item: {
        imagechangemode: 0,
      },
      uploadImage: [],
      casts: [],
      headers: [
        {
          text: "写真",
          value: "image",
          sortable: false,
          align: "center",
          width: 60,
        },
        { text: "キャスト名", value: "cast_name" },
      ],
      search: "",
    });

    const getCastList = async () => {
      // キャスト検索用リスト生成
      state.casts.splice(0);
      const params = new URLSearchParams();
      params.append("shop_id", store.getters["shops/currentShop"].id);
      await castsRepository
        .list(params)
        .then((response) => {
          if (response.data) {
            Object.keys(response.data).forEach(function (key) {
              state.casts.push({
                id: response.data[key].id,
                cast_name: response.data[key].name,
                image: response.data[key].image,
              });
            });
          }
        })
        .catch((error) => {
          throw (
            "ERROR:template/component/templateDialogComponent.vue/clearDialog castsRepository.list (" +
            error +
            ")"
          );
        });
    };

    const setData = async () => {
      state.item.imagechangemode = 0;
      // 各項目に値をセット
      const config = props.template_config;
      for (const [key, value] of Object.entries(config)) {
        const keyItem = key.split("_");
        if (keyItem[0] == "image") {
          set(state.uploadImage[keyItem[1]], "image_path", value);
        } else if (keyItem[0] == "imagechangemode") {
          set(state.item, "imagechangemode", value);
        }
      }
    };

    const clearData = async () => {
      // キャストリスト取得
      await getCastList();

      // 各種項目初期化
      for (let index = 0; index < setting.template_image_number; index++) {
        state.uploadImage.push({
          "image_path": null,
          "image_base64": null,
          "image_base64_type": null,
        });
      }
    };

    onMounted(async () => {
      // 初期化
      await clearData();
      // データセット
      if (props.template_config) {
        await setData(props.template_config);
      }
    });

    const onImageDelete = (index) => {
      set(state.uploadImage, index, {
        "image_path": null,
        "image_base64": null,
        "image_base64_type": null,
      });
    };

    const createImage = async (image, index) => {
      const reader = new FileReader();
      // imageをreaderにDataURLとしてattachする
      reader.readAsDataURL(image);
      // readAdDataURLが完了したあと実行される処理
      reader.onload = () => {
        set(state.uploadImage, index, {
          "image_path": null,
          "image_base64": reader.result,
          "image_base64_type": image.type,
        });
      };
    };

    const onImageUploaded = (e) => {
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      const type = files[0].type;
      if (
        type !== "image/jpeg" &&
        type !== "image/gif" &&
        type !== "image/png"
      ) {
        alert(".jpg、.gif、.pngのいずれかのファイルのみ許可されています");
        return;
      }
      createImage(files[0], e.target.getAttribute("upload_index"));
    };

    const onCastSelect = (imageIndex) => {
      state.castDialogIndex = imageIndex;
      state.castDialog = true;
    };

    const cancelCastDialog = () => {
      state.castDialog = false;
    };

    const onClickList = (cast) => {
      set(state.uploadImage, state.castDialogIndex, {
        "image_path": cast.image,
        "image_base64": null,
        "image_base64_type": null,
      });
      cancelCastDialog();
    };

    const saveConfig = async () => {
      const config = {};

      for (
        let imageIndex = 0;
        imageIndex < state.uploadImage.length;
        imageIndex++
      ) {
        if (state.uploadImage[imageIndex].image_base64) {
          // ここで画像保存処理(サーバにアップロードする)
          let ext = state.uploadImage[imageIndex].image_base64_type.substr(
            state.uploadImage[imageIndex].image_base64_type.indexOf("/") + 1
          );
          if (ext == "jpeg") {
            ext = "jpg";
          }
          const d = new Date();
          // アップロード用のファイル名を生成
          const file_name =
            "_tmp_" +
            store.getters["shops/currentShop"].id +
            "_" +
            d.getTime() +
            "." +
            ext;
          const image_params = {
            shop_id: store.getters["shops/currentShop"].id,
            base64_image: state.uploadImage[imageIndex].image_base64,
            file_name: file_name,
          };
          // ファイルアップロード
          const upload_result = await fileRepository
            .upload(image_params)
            .then((response) => {
              if (response.data) {
                if (response.data.status) {
                  // 画像追加OK
                  set(state.uploadImage, imageIndex, {
                    "image_path": response.data.file_name,
                    "image_base64": null,
                    "image_base64_type": null,
                  });
                } else {
                  // 画像追加NG
                  alert(response.data.message);
                }
                return response.data.status;
              }
            })
            .catch((error) => {
              throw (
                "ERROR:dialogComponent.vue/onSubmit templateRepository.create (" +
                error +
                ")"
              );
            });
          // アップロードに失敗した場合、抜ける。
          if (!upload_result) return;
        }
      }
      let saveIndex = 0;
      state.uploadImage.forEach((uploadImage) => {
        if (uploadImage.image_path) {
          config["image_" + saveIndex] = uploadImage.image_path;
          saveIndex++;
        }
      });
      // 更新毎に画像を切り替えるフラグ
      config["imagechangemode"] = state.item.imagechangemode;
      config["imagechangenum"] = 0; // 保存時0番に戻す

      return config;
    };

    // 返却オブジェクト定義
    return {
      dragOptions: {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      },
      drag: false,
      setting,
      ...toRefs(state),
      getCastList,
      cancelCastDialog,
      onImageDelete,
      onImageUploaded,
      onClickList,
      onCastSelect,
      saveConfig,
    };
  },
});
</script>

<style scoped>
.image_flame {
  overflow: auto;
}
.image_box {
  display: flex;
}
.image_card {
  margin-right: 0.25em;
  position: relative;
}
.image_drag_btn {
  position: absolute;
  top: 3px;
  right: 3px;
  z-index: 2;
}
</style>
