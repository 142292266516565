<template>
  <!-- テンプレート登録ダイアログ -->
  <div>
    <ValidationObserver ref="observer">
      <h3>更新方法</h3>
      <v-container fluid>
        <v-row>
          <v-col cols="12" sm="6" md="6">
            <v-select
              v-model="update_type"
              name="update_type"
              :items="updateTypeItem"
              no-data-text="更新方法を選択"
              label="更新方法"
              success
              outlined
              hint="更新方法を選択して下さい[必須項目]"
              persistent-hint
              :rules="require_rule"
              class="mt-2"
            ></v-select>
          </v-col>
        </v-row>
      </v-container>
      <h3>期間（開始〜終了）</h3>
      <v-container fluid>
        <v-row>
          <v-col cols="12" sm="6" md="3">
            <ValidationProvider
              v-slot="{ errors, valid }"
              name="掲載開始日"
              rules=""
            >
              <v-text-field
                v-model="start_date"
                label="掲載開始日"
                name="start_date"
                outlined
                type="date"
                hint="空白時は当日を自動設定します[任意項目]"
                persistent-hint
                :error-messages="errors"
                :success="valid"
                class="ml-1 mt-2"
                :clearable="true"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <ValidationProvider
              v-slot="{ errors, valid }"
              name="掲載終了日"
              rules=""
            >
              <v-text-field
                v-model="end_date"
                label="掲載終了日"
                name="end_date"
                outlined
                type="date"
                hint="[任意項目]"
                persistent-hint
                :error-messages="errors"
                :success="valid"
                class="ml-1 mt-2"
                :clearable="true"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
        </v-row>
      </v-container>
      <h3>タイトル</h3>
      <v-container fluid>
        <v-row>
          <v-col cols="12">
            <ValidationProvider
              v-slot="{ errors, valid }"
              name="タイトル"
              rules="required|max:40"
            >
              <v-text-field
                v-model="title"
                name="title"
                counter="40"
                :error-messages="errors"
                :success="valid"
                label="タイトル"
                outlined
                hint="タイトルは40文字以内で設定してください[必須項目]"
                persistent-hint
                background-color="white"
                class="mt-2"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
        </v-row>
      </v-container>

      <h3 id="body_head">内容</h3>
      <v-container fluid>
        <v-row>
          <v-col cols="12">
            <ValidationProvider
              v-slot="{ errors, valid }"
              name="内容"
              rules="required|max:500"
            >
              <v-textarea
                v-model="body"
                name="body"
                counter="500"
                :error-messages="errors"
                :success="valid"
                label="内容"
                rows="12"
                outlined
                hint="内容は500文字以内で設定してください[必須項目]"
                persistent-hint
                background-color="white"
                class="mt-2"
              />
            </ValidationProvider>
          </v-col>
        </v-row>
      </v-container>
      <!-- 画像選択コンポーネント -->
      <imageComponent
        ref="refImageComponent"
        :template_config="template_config"
        :is_contain="true"
      ></imageComponent>
      <p>
        ※投稿できる画像は1枚まで。横420px×縦315px（4：3の横長）を推奨サイズとしています。
      </p>
    </ValidationObserver>
  </div>
</template>

<script>
import {
  reactive,
  toRefs,
  defineComponent,
  onMounted,
} from "@vue/composition-api";
import setting from "@/common/setting.js";
import imageComponent from "./itemComponent/image";

export default defineComponent({
  components: {
    imageComponent,
  },
  props: ["template_config"],
  setup(props, ctx) {
    const state = reactive({
      update_type: 1,
      start_date: "",
      end_date: "",
      title: "",
      body: "",
    });

    const updateTypeItem = [
      { value: 1, text: "常に新しく記事を追加する" },
      { value: 2, text: "同じ件名の記事は上書き更新(上位化)する" },
    ];

    const clearDialog = async () => {
      state.update_type = 1;
      state.start_date = "";
      state.end_date = "";
      state.title = "";
      state.body = "";
    };

    const setData = async () => {
      // 各項目に値をセット
      const config = props.template_config;
      state.update_type = config.update_type ?? 1;
      state.start_date = config.start_date ?? "";
      state.end_date = config.end_date ?? "";
      state.title = config.title_40;
      state.body = config.body_500;
    };

    onMounted(async () => {
      // 初期化
      await clearDialog();
      // データセット
      if (props.template_config) {
        await setData(props.template_config);
      }
    });

    // 保存前のチェック処理
    const checkValid = async () => {
      // 入力チェック エラー状態の部品が無いか調べる
      const isValid = await ctx.refs.observer.validate();
      if (!isValid) {
        // エラー処理
        const el = document.querySelectorAll(
          ".v-text-field.error--text, .v-textarea.error--text"
        );
        if (el.length > 0) {
          el[0].scrollIntoView();
        }
      }

      return isValid;
    };

    // 保存処理
    const onSubmit = async () => {
      // 保存処理
      const config = {};
      config["update_type"] = state.update_type;
      config["start_date"] = state.start_date;
      config["end_date"] = state.end_date;
      config["title_40"] = state.title;
      config["body_500"] = state.body;

      // 画像コンポーネントの設定を取得
      const imageConfig = await ctx.refs.refImageComponent.saveConfig();

      return JSON.stringify({ ...config, ...imageConfig });
    };

    // 返却オブジェクト定義
    return {
      setting,
      updateTypeItem,
      ...toRefs(state),
      checkValid,
      onSubmit,
      require_rule: [(v) => !!v || "必須選択項目です"],
    };
  },
});
</script>

<style scoped></style>
