var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{ref:"observer"},[_c('h3',[_vm._v("ニュース種別")]),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-select',{staticClass:"mt-2",attrs:{"name":"news_category","items":_vm.newsCategories,"no-data-text":"ニュース種別を選択","label":"ニュース種別","success":"","outlined":"","hint":"ニュース種別を選択して下さい[必須項目]","rules":_vm.require_rule},model:{value:(_vm.news_category),callback:function ($$v) {_vm.news_category=$$v},expression:"news_category"}})],1)],1)],1),_c('h3',[_vm._v("公開終了時刻設定")]),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-checkbox',{ref:"indefinite",attrs:{"name":"indefinite","label":"終了時刻を設定","value":"1","hide-details":""},model:{value:(_vm.indefinite_selected),callback:function ($$v) {_vm.indefinite_selected=$$v},expression:"indefinite_selected"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('ValidationProvider',{attrs:{"name":"終了時刻","rules":!_vm.indefinite_selected || _vm.indefinite_selected.length == 0
                        ? ''
                        : 'required|numeric|min_value:1|max_value:99'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
                        var valid = ref.valid;
return [_c('v-text-field',{staticClass:"ml-4",attrs:{"name":"end_publish_between","error-messages":errors,"success":valid,"outlined":"","background-color":"white","suffix":"時間後","disabled":!_vm.indefinite_selected ||
                        _vm.indefinite_selected.length == 0,"persistent-hint":true,"hint":"公開終了時刻を公開から何時間後にするか設定します"},model:{value:(_vm.end_publish_between),callback:function ($$v) {_vm.end_publish_between=$$v},expression:"end_publish_between"}})]}}])})],1)],1)],1)],1)],1)],1)],1),_c('h3',[_vm._v("題名")]),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"題名","rules":"required|max:1000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
                        var valid = ref.valid;
return [_c('v-text-field',{staticClass:"mt-2",attrs:{"name":"title","counter":"1000","error-messages":errors,"success":valid,"label":"題名","outlined":"","background-color":"white"},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}})]}}])})],1)],1)],1),_c('h3',{attrs:{"id":"body_head"}},[_vm._v("本文")]),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"本文","rules":"required|max:10000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
                        var valid = ref.valid;
return [_c('v-textarea',{staticClass:"mt-2",attrs:{"name":"body","counter":"10000","error-messages":errors,"success":valid,"label":"本文","rows":"12","outlined":"","background-color":"white"},model:{value:(_vm.body),callback:function ($$v) {_vm.body=$$v},expression:"body"}})]}}])})],1)],1)],1),_c('imageComponent',{ref:"refImageComponent",attrs:{"template_config":_vm.template_config}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }